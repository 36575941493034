/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*global class*/

.text-principal {
  color: var(--ion-color-primary) !important;
}

.bg-principal {
  background-color: var(--ion-color-primary) !important;
}

:root .swal2-height-auto {
  height: 100% !important;
}

ion-toolbar {
  padding: 0px 20px !important;
}

.ion-page {
  display: block !important;
}

@font-face {
  font-family: "Pragmatica";
  src: url("./assets/fonts/Pragmatica-Medium_36334.ttf");
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Segoe UI Semibold";
  src: url("./assets/fonts/Segoe UI Bold.ttf");
}

@font-face {
  font-family: "Segoe UI";
  src: url("./assets/fonts/Segoe UI.ttf");
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

*{
  font-family: Pragmatica;
}

.alert-btn-cancel {
  --color: #eb445a !important;
  color: #eb445a !important;
}

/*sweetAlert Styles*/

.swal2-icon.swal2-question,
.swal2-icon.swal2-success {
  border-color: #fec401 !important;
  color: #fec401 !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #fec401 !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 4px solid #fec401 !important;
}

.swal2-title,
.swal2-content {
  color: black !important;
}

ion-card-title {
  color: #01bc88 !important;
  font-weight: bold;
}

.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.my-custom-class {
  --width: 95vw !important;
}

.custom-padding-page {
  padding-bottom: 4rem;
}
.btn-success {
  background-color: #00bc88;
  border-color: transparent !important;
}

hr {
  margin: 15px 0 0 0;
}


.ion-padding{
  padding-bottom: 100px;
}

.pagination {
  justify-content: flex-end !important;
}

.pagination a {
  border-radius: 50%;
}

.page-item.active .page-link {
  background-color: var(--ion-color-primary);
  border: var(--ion-color-primary);
}

.page-link {
  color: var(--ion-color-primary) !important;
}

.page-item.active .page-link {
  color: white !important;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
  border-radius: 50% !important;
}

.pagination li {
  margin-right: 0.5rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.pagination-prev a, .pagination-next a {
  width: 34.4px !important;
  text-align: center;
}


ion-alert {
  //background color
  .alert-wrapper.sc-ion-alert-md {
    background-color: white;
    --background: white;
  }

  // Header Text
  .alert-title.sc-ion-alert-md {
    font-size: 25px;
    font-weight: 600;
    font-family: "Poppins";
    color: black;
  }
  .alert-radio-label.sc-ion-alert-md {
    color: black;
}
}

.alert-title {
  font-family: Pragmatica !important;
}
